/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAhN0aa5dwCp1KMe363KFN_jirjWbtPT0U",
  "appId": "1:955898436478:web:9de87f5bd08760737ef12f",
  "authDomain": "schooldog-i-jeffersoncity-ga.firebaseapp.com",
  "measurementId": "G-8JS6J3QSQD",
  "messagingSenderId": "955898436478",
  "project": "schooldog-i-jeffersoncity-ga",
  "projectId": "schooldog-i-jeffersoncity-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-jeffersoncity-ga.appspot.com"
}
